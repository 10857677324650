import { useEffect } from "react";
import Head from "next/head";
import Image from "next/image";

import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import LandingHeader from "../../components/LandingHeader";
import LandingFooter from "../../components/LandingFooter";
import DemoForm from "../../components/forms/Form";

import { getCookie } from "cookies-next";

import fonts from "../../styles/FontInter.module.css";
import mainTheme from "../../components/mainTheme";
import styles from "../../styles/Home.module.css";

export default function TemplatePage({ utm }) {
  useEffect(() => {
    dataLayer.push({ event: "optimize.activate" });
  }, []);

  return (
    <ThemeProvider theme={mainTheme}>
      <Head>
        <title>Social Media Marketing 101 Guide - courtesy of Elm Street</title>
        <meta
          name="description"
          content="Download Your FREE, All-Inclusive Guide For Creating GREAT Content! Includes: 4 Month Marketing Content Calendar!"
        />
        <link rel="icon" href="/favicon.ico" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@weareelmstreet" />
        <meta name="twitter:creator" content="@weareelmstreet" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://elmstreet.com/socialmediamarketingguide" />
        <meta property="og:title" content="Social Media Marketing 101 Guide - courtesy of Elm Street" />
        <meta
          property="og:description"
          content="Download Your FREE, All-Inclusive Guide For Creating GREAT Content! Includes: 4 Month Marketing Content Calendar!"
        />
        <meta property="og:image" content="https://elmstreet.com/og_images/elmstreetog.jpg" />
      </Head>
      <CssBaseline className={fonts} />

      <LandingHeader />

      <div
        style={{
          backgroundImage: "url('/photobooth/photoboothbg.jpg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          display: "block",
        }}
      >
        <Container
          maxWidth="lg"
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "55px",
            paddingBottom:"55px",
          }}
        >
          <div style={{ marginTop: "55px", textAlign:"center", marginBottom:"55px" }}>
            <Grid container spacing={8}>
              <Grid item md={12} sm={12} style={{ paddingBottom: "20px" }}>
              <div style={{paddingBottom:"15px"}}>
                    <Image src="/es_vwhite.svg" width="300px" height="231px" alt="Elm Street" />
                  </div>
                <Typography variant="h3" component="h1" style={{ color: "#ffffff" }}>
                “Be professionally<br />
                good looking.”<br />
<span style={{color:"#f5cd28", fontSize:"16px"}}>Derek Zoolander</span>
                </Typography>
              </Grid>
             
            </Grid>
          </div>
        </Container>
      </div>

      <Container maxWidth="lg" style={{ textAlign: "left" }}>
        <div style={{ marginTop: "88px", marginBottom: "88px" }}>
          <Grid container spacing={8} justifyContent="center">
            <Grid item md={8}>
            <DemoForm
              buttonText="Submit"
              successMsg={<div style={{textAlign:"center"}}><strong>Thank you!</strong><br/><br/>A smile is something everyone can wear and look good. You will receive an email with a link to your headshot next week.</div>}
              formName="es2_photobooth" listId="149061"
              fieldList={["first_name", "last_name", "title", "email", "phone"]}
            />
            </Grid>
          </Grid>
        </div>
      </Container>

      <LandingFooter />
    </ThemeProvider>
  );
}

export const getServerSideProps = ({ req, res }) => {
  let cookies = getCookie("elmlead", { req, res });
  if (cookies) {
    return { props: { utm: cookies } };
  }
  return { props: {} };
};
